import { getDefaultDateQuery } from '@components/EventIndex/useEventDateQuery';
import { EntriesFragment } from '@gql/_generated/entries.generated';
import { Entry, parseEntries } from '@lib/features/entries';
import client from '@lib/fetch/client';
import { withSite } from '@lib/hooks';
import { parseApiError } from '@lib/parse/parseApiError';
import { gql } from 'graphql-request';
import { useCallback, useState } from 'react';
import { EventsQueryVariables, getSdk } from './_generated/useEvents.generated';

gql`
  query events(
    $site: [String] = "default"
    $limit: Int = 4
    $isEverydayEvent: Boolean = null
    $orderBy: String = "eventDateStart ASC"
    $eventDateStart: [QueryArgument] = null
    $eventDateEnd: [QueryArgument] = null
  ) {
    entries: entries(
      site: $site
      isEverydayEvent: $isEverydayEvent
      section: "event"
      limit: $limit
      orderBy: $orderBy
      eventDateStart: $eventDateStart
      eventDateEnd: $eventDateEnd
    ) {
      ...eventCard
    }
  }
`;

const sdk = getSdk(client);

export type UseEventsProps = EventsQueryVariables & {
  includePastEvents?: boolean;
};

export const useEvents = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [items, setItems] = useState<Entry<'event'>[]>([]);

  const trigger = useCallback(({ includePastEvents, ...maybeArgs }: UseEventsProps) => {
    const args = {
      ...maybeArgs,
      ...(!includePastEvents && !maybeArgs.isEverydayEvent && getDefaultDateQuery()),
    };

    setIsLoading(true);
    setIsError(false);
    sdk
      .events(withSite(args))
      .then((res) => {
        const maybeEvents = res?.entries ?? [];
        const parsedEvents = parseEntries(maybeEvents as EntriesFragment[], [
          'event_default_Entry',
          'event_ticketed_Entry',
        ]);
        setIsLoading(false);
        setItems(parsedEvents);
        // log
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn(parseApiError(err));
      });
  }, []);

  return { trigger, items, isError, isLoading };
};
