import BlockHeader from '@components/BlockHeader';
import { BlockContainer } from '@components/Blocks';
import CardGrid from '@components/CardGrid';
import CtaButton from '@components/CtaButton';
import { EntryCardDefault } from '@components/EntryCard';
import { Entry, isEntry } from '@lib/features/entries';
import { useAppLink, useView } from '@lib/store';
import { useTranslations } from 'next-intl';

export type EventCardsProps = {
  heading?: string | null;
  content?: string | null;
  items?: Entry<'event'>[];
};

const EventCards = ({ heading, content, items = [] }: EventCardsProps) => {
  const indexLink = useAppLink('eventIndex');
  const t = useTranslations('event');
  const indexHref = indexLink?.uri || undefined;
  const view = useView();

  const showIndexLink = !isEntry(view, 'eventIndex');

  return (
    <BlockContainer>
      <BlockHeader
        {...{
          variant: 'split',
          heading,
          HeadingProps: { variant: 'h1' },
          content,
          cta: showIndexLink && indexHref && (
            <CtaButton variant="text" href={indexHref}>
              {t('indexLink')}
            </CtaButton>
          ),
        }}
      />
      <CardGrid Component={EntryCardDefault} sm={6} md={4} lg={3} items={items} />
    </BlockContainer>
  );
};

export default EventCards;
